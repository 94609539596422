import type { BrowserOptions } from "@sentry/browser";
import type { NodeOptions } from "@sentry/node";

// cspell: ignore adnxs xsca ampproject amphtml vpaid tinypass moatvideo ftpagefold_ pagead sdkloader lastpass crwdcntrl doubleverify adsystem adlooxtracking connatix
export const config: NodeOptions | BrowserOptions = {
  attachStacktrace: true,
  // refs: https://forum.sentry.io/t/node-js-filtering-with-beforesend-not-having-any-effect/8833
  denyUrls: [
    /\/xsca\.[\d.]+\.js\b/,
    /https:\/\/raw\.githubusercontent\.com/,
    /cdn\.adnxs-simple\.com/,
    /\/instream\/video\/client\.js/,
    /\/ads\/scripts\/iiq_ev1\.js/,
    /\/vpaid\/units\/.*cmTagEXPANDABLE\.js/,
    /amp4ads-v0\.js/,
    /cmTagEXPANDABLE\.js/,
    /tinypass\.min\.js/,
    /www\.instagram\.com/,
    /cdn\.doubleverify\.com/,
    /\/moatvideo\.js/,
    /\/load_tags\.js/,
    /\/www\.gstatic\.com/,
    /\/pageFold\/ftpagefold_/,
    /\/gtm\.js\b/,
    /\/gsi\/client/,
    /\/metrics\.js\b/,
    /\/pagead\/expansion_embed\.js\b/,
    /\/js\/sdkloader\/ima3\.js\b/,
    /\/rq\/iv\/inside\.js\b/,
    /\/hearst\/news-3p\//,
    /\/mirror\.js/,
    /\/c\.amazon-adsystem/,
    /\/chartbeat_video\.js/,
    /cdn\.doubleverify\.com/,
    /should_do_lastpass_here/,
    /tags\.crwdcntrl\.net/,
    /\/s\.ntv\.io/,
    /\/storage\.googleapis\.com/,
    /j\.adlooxtracking\.com/,
    /connatix\.com/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN ?? "",
  ignoreErrors: [
    "a CORS error above may indicate that this publisher or domain is not configured in Publisher Center",
    "a.getDuration is not a function",
    "AbortError",
    "Access is denied for this document",
    "Ad request reached a timeout",
    "An attempt was made to break through the security policy of the user agent.",
    "Blocked a restricted frame",
    "Cannot read properties of null",
    "captured as promise rejection",
    "Failed to execute 'querySelector'",
    "Failed to fetch",
    "Failed to register a ServiceWorker",
    "Invariant: attempted to hard navigate to the same URL",
    "is not valid JSON",
    "load failed",
    "Load failed",
    "Missing required parameters: sitekey",
    "Network Error",
    "Non-Error promise rejection captured with value",
    "r.getAll is not a function",
    "Request failed with status code 401",
    "ResizeObserver loop completed with undelivered notifications.",
    "ServiceWorker script",
    "The browser you are using isn't compatible with this application",
    "The node to be removed is not a child of this node",
    "The operation is insecure.",
    "The request is not allowed by the user agent",
    "The VAST response document is empty.",
    "There was a problem requesting ads from the server",
    "This Suspense boundary received",
    "this.getContainer().ownerDocument",
    "Unexpected token",
    /ezproxy/,
    /Suspense boundary/,
  ],
  // cspell: disable-line
  release: process.env.NEXT_PUBLIC_COMMIT_SHA,
};
