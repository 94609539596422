import { sendGA4GTMTracking } from "scmp-app/components/tracking/google-tag-manager/apis";

import { type Event, type UntypedEvent } from "./types";

export const checkIsContainsPlusQueryString = () => {
  const urlParameters = new URLSearchParams(window.location.search);
  const display = urlParameters.get("display");

  if (display === "plus" || window.location.pathname.split("/")?.[1] === "plus") {
    return true;
  }
};

export function sendGA4Tracking<Untyped extends true | false = false>(
  parameters: Untyped extends true ? UntypedEvent : Event,
  options?: { untyped: Untyped },
) {
  const isPlus = checkIsContainsPlusQueryString();

  const getParameters = () => ({
    ...parameters,
    customized_parameters: {
      // flush the customized parameters to clear the previous tracking parameters
      ...parameters?.customized_parameters,
      ...(isPlus && { product: "plus" }),
      // if page_location is not provided, use the current window location
      ...(!parameters?.customized_parameters?.page_location && {
        page_location: window.location.href,
      }),
    },
  });

  sendGA4GTMTracking(
    {
      _clear: true,
      event: "GA4_tracking",
      ...getParameters(),
    },
    options,
  );
}
