import Script from "next/script";
import type { FunctionComponent } from "react";

export const ChartbeatScripts: FunctionComponent = () => (
  <>
    <Script
      id="chartbeat"
      src="https://static.chartbeat.com/js/chartbeat_mab.js"
      strategy="lazyOnload"
    />
  </>
);

ChartbeatScripts.displayName = "ChartbeatScripts";
