import type { FunctionComponent } from "react";
import { JsonLd } from "react-schemaorg";
import type { LocalBusiness, Organization, WebSite } from "schema-dts";

import { config } from "scmp-app/data";
import { getAssetPrefix } from "scmp-app/lib/utils";

export const AppLinkingData: FunctionComponent = () => (
  <>
    <JsonLd<Organization>
      item={{
        "@context": "https://schema.org",
        "@id": `${config.general.siteUrl}#organization`,
        "@type": "Organization",
        contactPoint: {
          "@type": "ContactPoint",
          contactType: "customer support",
          telephone: "+852-2680-8888",
        },
        logo: `${getAssetPrefix()}/icons/scmp-icon-512x512.png`,
        sameAs: [
          "https://www.facebook.com/southchinamorningpost/",
          "https://twitter.com/SCMPNews",
          "https://www.youtube.com/southchinamorningpost",
          "https://www.linkedin.com/company/south-china-morning-post",
          "https://www.instagram.com/scmpnews",
          "https://plus.google.com/b/109548181662196012156/+SouthChinaMorningPostSCMP",
        ],
        url: config.general.siteUrl,
      }}
    />
    <JsonLd<LocalBusiness>
      item={{
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        address: "19/F Tower One, Times Square, 1 Matheson Street, Causeway Bay, Hong Kong",
        description:
          "First published in 1903, South China Morning Post is Hong Kong’s premier English language newspaper and has the city’s most affluent and influential readership. With a reputation for authoritative, influential and independent reporting on Hong Kong and China. The newspaper is supported with its online publication scmp.com and its Sunday edition, Sunday Morning Post.",
        image: `${getAssetPrefix()}/icons/scmp-icon-512x512.png`,
        logo: `${getAssetPrefix()}/icons/scmp-icon-512x512.png`,
        name: "SCMP",
        telephone: "+852-2680-8888",
        url: config.general.siteUrl,
      }}
    />

    <JsonLd<WebSite>
      item={{
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: "South China Morning Post",
      }}
    />
  </>
);

AppLinkingData.displayName = "AppLinkingData";
