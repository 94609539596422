import { useSetAtom } from "jotai";
import Script from "next/script";
import type { FunctionComponent } from "react";

import { googleImaAtom } from "./atoms";

// cspell: ignore Iframes
declare global {
  interface Window {
    _mirror_config?: {
      autoDetectYouTubeIframes: boolean;
      enableVideo: boolean;
    };
  }
}

export const GoogleIMAScripts: FunctionComponent = () => {
  const setGoogleIma = useSetAtom(googleImaAtom);
  return (
    <>
      <Script
        id="youtube-iframe-api"
        src="https://www.youtube.com/iframe_api?ima=1"
        strategy="lazyOnload"
        onLoad={() => {
          window._mirror_config = {
            ...window._mirror_config,
            autoDetectYouTubeIframes: false,
            enableVideo: true,
          };
        }}
      />
      <Script
        id="google-ima"
        src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"
        strategy="lazyOnload"
        onError={() => {
          setGoogleIma({
            isImaReady: true,
          });
        }}
        onLoad={() => {
          setGoogleIma({
            isImaReady: true,
          });
        }}
      />
    </>
  );
};

GoogleIMAScripts.displayName = "GoogleIMAScripts";
