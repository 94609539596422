import { ZIndexLayer as SdkZIndexLayer } from "@product/scmp-sdk";

export enum ZIndexLayer {
  NewsletterArticleFloatingWidget = 1000,
  ArticleAuthorFollowButton = 1001,
  AppTooltip = 1100,
  TakeoverAd = 1101,
  StoryCardCarouselWidget = 1102,
  NewsletterArticleIssues = 1200,
  PlusDatePicker = 1202,
  AppBar = 1300,
  DropdownSelect = 1350,
  Drawer = 1400,
  Modal = SdkZIndexLayer.Modal,
  SnackBar = 1600,
  Tooltip = SdkZIndexLayer.Tooltip,
  HamburgerMenu = 1800,
  EditionInvitation = 1900,
  GoogleOneTapSignIn = 2000,
  PlusPopup = 2100,
  PlusListingPopup = 2200,
  OnboardingDialog = 3000,
  LiveUpdateAlert = 4000,
}
