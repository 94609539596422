import { useIsomorphicLayoutEffect } from "@react-hookz/web";

import { setGTMCustomDimension } from "scmp-app/components/tracking/google-tag-manager/apis";
import { useCanonicalUrl } from "scmp-app/lib/hooks";
import { isBotUserAgent } from "scmp-app/lib/utils";

export const useInitTrackingSetup = () => {
  const { canonicalUrl: canonicalURL } = useCanonicalUrl();
  const isBot = isBotUserAgent();

  useIsomorphicLayoutEffect(() => {
    const data = {
      canonicalURL,
      isBot,
    };
    // Use for the events before page view sent
    setGTMCustomDimension(data);
  }, [canonicalURL, isBot]);
};
