import { NoSsr } from "@mui/base";
import type { PageProps } from "next";
import type { FunctionComponent, ReactNode } from "react";

import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { GoogleOneTapLoginContainer } from "scmp-app/components/google-one-tap-login";
import { DynamicPlusArticlePopup } from "scmp-app/components/plus/article-popup/dynamics";
import { DynamicPlusDetailedListingPopup } from "scmp-app/components/plus/detailed-listing-popup/dynamics";
import { PlusLearnMorePlaceholder } from "scmp-app/pages/plus/learnmore/placeholder";
import { PlusLearnMoreSuspense } from "scmp-app/pages/plus/learnmore/suspense";

import {
  DynamicAppBar,
  DynamicContentReactionActionRedirect,
  DynamicHamburgerMenu,
  DynamicLoginDialog,
  DynamicNewsTicker,
  DynamicPlusPaywallHookRunner,
  DynamicPopupContainer,
  DynamicSimpleHooksRunner,
} from "./dynamics";

export type Props = {
  children: ReactNode;
  pageProps?: PageProps;
};

/**
 * This component is for placing application specific features and also the application header bar
 */
export const AppInitializer: FunctionComponent<Props> = ({ children, pageProps }) => {
  const application = pageProps?.appInitializerConfiguration?.application ?? "scmp";

  switch (application) {
    case "plus":
      return (
        <>
          <NoSsr>
            <DynamicSimpleHooksRunner />
            <DynamicLoginDialog />
            <DynamicPlusPaywallHookRunner>
              <DynamicPlusArticlePopup />
              <DynamicPlusDetailedListingPopup />
            </DynamicPlusPaywallHookRunner>
          </NoSsr>
          <DynamicAppBar pageProps={pageProps}>
            <NoSsr>
              <IdleQueue>
                <DynamicNewsTicker appBarVariant={pageProps?.appBarConfiguration?.variant} />
              </IdleQueue>
            </NoSsr>
          </DynamicAppBar>
          <PlusLearnMoreSuspense fallback={<PlusLearnMorePlaceholder />}>
            {children}
          </PlusLearnMoreSuspense>
        </>
      );
    case "posties":
      return (
        <>
          <IdleQueue>
            <DynamicHamburgerMenu variant={pageProps?.appBarConfiguration?.variant} />
          </IdleQueue>
          <NoSsr>
            <DynamicSimpleHooksRunner />
            <DynamicLoginDialog />
          </NoSsr>
          <DynamicAppBar pageProps={pageProps} />
          {children}
        </>
      );
    default:
      return (
        <>
          <IdleQueue>
            <DynamicPopupContainer />
            <DynamicHamburgerMenu variant={pageProps?.appBarConfiguration?.variant} />
          </IdleQueue>
          <NoSsr>
            <DynamicSimpleHooksRunner />
            <DynamicContentReactionActionRedirect />
            <DynamicLoginDialog />
          </NoSsr>
          <DynamicAppBar pageProps={pageProps}>
            <NoSsr>
              <IdleQueue>
                <GoogleOneTapLoginContainer />
                <DynamicNewsTicker appBarVariant={pageProps?.appBarConfiguration?.variant} />
              </IdleQueue>
            </NoSsr>
          </DynamicAppBar>
          {children}
        </>
      );
  }
};

AppInitializer.displayName = "AppInitializer";
